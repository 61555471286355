import request from './../util/request';

export const getLaunchInfoApi = () => request.get('/crm/crmLaunchApi');
// export const getLaunchInfoApi = () =>
//   Promise.resolve({
//     action: 'success',
//     data: {
//       email: 'parth.local@halalbox.in',
//       phoneNo: '2345234523',
//       userFullName: 'Parth Local',
//       authResponse: {
//         menuSet: ['PU', 'CRM'],
//         menuList: [
//           {
//             menu: 'PU',
//             menuSectionList: [
//               {
//                 menuSection: 'CREATE_QR_CODE',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'GENERATE_SKU_PRINT' },
//                   { authorityCode: 'GENERATE_QR' },
//                   { authorityCode: 'REPRINT_QR' },
//                   { authorityCode: 'CREATE_QR_CODE_SECTION' },
//                   { authorityCode: 'SEARCH_QR_SKU' },
//                 ],
//               },
//               {
//                 menuSection: 'INVOICES',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'SEARCH_INVOICE' },
//                   { authorityCode: 'ADD_TO_INVOICE' },
//                   { authorityCode: 'VIEW_INVOICE' },
//                   { authorityCode: 'SEARCH_SKU_INVOICE' },
//                   { authorityCode: 'GENERATE_DRAFT_INVOICE' },
//                   { authorityCode: 'ADD_SKU_TO_INVOICE' },
//                   { authorityCode: 'LINK_SKID' },
//                   { authorityCode: 'EDIT_SKID_QUANTITY' },
//                   { authorityCode: 'REMOVE_SKU_FROM_INVOICE' },
//                   { authorityCode: 'GENERATE_INVOICE' },
//                   { authorityCode: 'LINK_VEHICLE_TO_INVOICE' },
//                   { authorityCode: 'PRINT_INVOICE' },
//                   { authorityCode: 'INVOICE_SECTION' },
//                   { authorityCode: 'CREATE_INVOICE' },
//                 ],
//               },
//               {
//                 menuSection: 'LINK_PRODUCTS_SKID',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'LINK_PRODUCT_SKID_SECTION' },
//                 ],
//               },
//               {
//                 menuSection: 'ON_SCAN_DEMO',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ON_SCAN_DEMO_SECTION' },
//                   { authorityCode: 'SCAN_WITH_MULTIPLE_SKID_NO' },
//                 ],
//               },
//               {
//                 menuSection: 'PRODUCT_LINKED_SKIDS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'PRODUCT_LINKED_SKID_SECTION' },
//                 ],
//               },
//             ],
//           },
//           {
//             menu: 'CRM',
//             menuSectionList: [
//               {
//                 menuSection: 'ADC_PINCODE',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'EDIT_ADC_PINCODE' },
//                   { authorityCode: 'ADD_ADC_PINCODE' },
//                   { authorityCode: 'VIEW_ADC_PINCODE' },
//                   { authorityCode: 'DELETE_ADC_PINCODE' },
//                   { authorityCode: 'VIEW_ADC_PINCODE_MENU' },
//                 ],
//               },
//               {
//                 menuSection: 'ADC_SETUP',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ADC_COVERAGE_MENU' },
//                   { authorityCode: 'ADC_SETUP_MENU' },
//                 ],
//               },
//               {
//                 menuSection: 'APP_UPDATE',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'EDIT_APP_UPDATE' },
//                   { authorityCode: 'VIEW_APP_UPDATE' },
//                 ],
//               },
//               {
//                 menuSection: 'AUTH_CODES',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'EDIT_AUTH_CODES' },
//                   { authorityCode: 'ADD_AUTH_CODES' },
//                   { authorityCode: 'VIEW_AUTH_CODES' },
//                 ],
//               },
//               {
//                 menuSection: 'BLOGS',
//                 menuAuthorityCodes: [{ authorityCode: 'BLOGS_SECTION' }],
//               },
//               {
//                 menuSection: 'BULK_PRESALE_SETUP',
//                 menuAuthorityCodes: [{ authorityCode: 'ADD_BULK_PRESALE' }],
//               },
//               {
//                 menuSection: 'CRM_DASHBOARD',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_REGULAR_PRODUCTS_CHART' },
//                 ],
//               },
//               {
//                 menuSection: 'CRON_JOBS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_CRON_JOBS' },
//                   { authorityCode: 'START_CRON_JOBS' },
//                 ],
//               },
//               {
//                 menuSection: 'CUSTOMERS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ADD_CUSTOMER' },
//                   { authorityCode: 'RECHARGE_WALLET' },
//                   { authorityCode: 'COPY_OFC_LINK' },
//                   { authorityCode: 'LOGIN_AS_CUSTOMER' },
//                   { authorityCode: 'CUSTOMERS_SECTION' },
//                   { authorityCode: 'SEARCH_CUSTOMER' },
//                   { authorityCode: 'VIEW_CUSTOMER' },
//                   { authorityCode: 'ORDER_FOR_CUSTOMER' },
//                 ],
//               },
//               {
//                 menuSection: 'CUSTOM_TILES',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'CUSTOM_TILES_SECTION' },
//                   { authorityCode: 'EDIT_CUSTOM_TILE' },
//                   { authorityCode: 'ADD_CUSTOM_TILE' },
//                   { authorityCode: 'VIEW_CUSTOM_TILE' },
//                 ],
//               },
//               {
//                 menuSection: 'ENTITY_AND_KYC',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_ENTITY_AND_KYC_DETAILS' },
//                   { authorityCode: 'UPDATE_KYC_STATUS' },
//                   { authorityCode: 'VIEW_ENTITY_LIST' },
//                 ],
//               },
//               {
//                 menuSection: 'FAILED_ORDERS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_FAILED_ORDER' },
//                   { authorityCode: 'UPDATE_FAILED_ORDER' },
//                 ],
//               },
//               {
//                 menuSection: 'HB_MONEY',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_HB_MONEY_SECTION' },
//                   { authorityCode: 'RECHARGE_HB_MONEY' },
//                   { authorityCode: 'BULK_WALLET_RECHARGE' },
//                   { authorityCode: 'VIEW_WALLET_CUSTOMERS' },
//                   { authorityCode: 'VIEW_HB_MONEY' },
//                 ],
//               },
//               {
//                 menuSection: 'HB_POINTS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_HBP_REDEEM' },
//                   { authorityCode: 'ADD_HBP_REDEEM' },
//                   { authorityCode: 'EDIT_HBP_EARN' },
//                   { authorityCode: 'EDIT_HBP_REDEEM' },
//                   { authorityCode: 'ADD_HBP_EARN' },
//                   { authorityCode: 'VIEW_HBP_EARN' },
//                 ],
//               },
//               {
//                 menuSection: 'HB_PRIME',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_PRIME_PLANS_LIST' },
//                   { authorityCode: 'ADD_PRIME_PLAN' },
//                   { authorityCode: 'VIEW_PRIME_MEMBERS' },
//                   { authorityCode: 'VIEW_HB_PRIME_MEMBERS_MENU' },
//                   { authorityCode: 'VIEW_HB_PRIME_MENU' },
//                   { authorityCode: 'EDIT_PRIME_PLAN' },
//                   { authorityCode: 'VIEW_MEMBERSHIP_LIST' },
//                 ],
//               },
//               {
//                 menuSection: 'HOMEPAGE_SETUP',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'HOMEPAGE_SECTION' },
//                   { authorityCode: 'UPDATE_HOMEPAGE' },
//                 ],
//               },
//               {
//                 menuSection: 'LOGISTIC_TRACKING',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'LOGISTIC_TRACKING_MENU' },
//                   { authorityCode: 'SEARCH_LOGISTIC_TRACKING' },
//                   { authorityCode: 'VIEW_LOGISTIC_TRACKING' },
//                 ],
//               },
//               {
//                 menuSection: 'NOTIFICATION',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_NOTIFICATION' },
//                   { authorityCode: 'ADD_NOTIFICATION' },
//                   { authorityCode: 'EDIT_NOTIFICATION' },
//                 ],
//               },
//               {
//                 menuSection: 'ORDERS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_ADC_ASSIGNED_SKIDS' },
//                   { authorityCode: 'PROCESS_CRR' },
//                   { authorityCode: 'RESCHEDULE_ORDER' },
//                   { authorityCode: 'VIEW_EXPECTED_INVENTORY' },
//                   { authorityCode: 'VIEW_ORDER_OTP' },
//                   { authorityCode: 'VIEW_ADC_INVENTORY' },
//                   { authorityCode: 'EDIT_ADC_INVENTORY' },
//                   { authorityCode: 'CHECK_PAST_ORDERS' },
//                   { authorityCode: 'UPDATE_ORDER_STATUS' },
//                   { authorityCode: 'VIEW_ORDER' },
//                   { authorityCode: 'SEARCH_ORDER' },
//                   { authorityCode: 'ORDERS_SECTION' },
//                   { authorityCode: 'SIMULATE_ORDER' },
//                 ],
//               },
//               {
//                 menuSection: 'PRODUCTS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ADD_PRODUCT' },
//                   { authorityCode: 'TOGGLE_WEEKEND_SPL_SKU' },
//                   { authorityCode: 'VIEW_COMBO_PRODUCTS' },
//                   { authorityCode: 'EDIT_COMBO_PRODUCT' },
//                   { authorityCode: 'ADD_COMBO_PRODUCT' },
//                   { authorityCode: 'EDIT_PRODUCT' },
//                   { authorityCode: 'EDIT_DEAL' },
//                   { authorityCode: 'PRODUCTS_SECTION' },
//                   { authorityCode: 'SEARCH_PRODUCT' },
//                   { authorityCode: 'EDIT_PRICE' },
//                   { authorityCode: 'VIEW_PRODUCTS' },
//                   { authorityCode: 'VIEW_CATEGORY_SETUP' },
//                   { authorityCode: 'UPSERT_CATEGORY_SETUP' },
//                   { authorityCode: 'DELETE_CATEGORY_SETUP' },
//                 ],
//               },
//               {
//                 menuSection: 'PROMO_SETUP',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ADD_PROMO' },
//                   { authorityCode: 'VIEW_PROMO' },
//                   { authorityCode: 'PROMO_SECTION' },
//                   { authorityCode: 'EDIT_PROMO' },
//                 ],
//               },
//               {
//                 menuSection: 'PROVISIONAL_CATEGORY',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_PROVISIONAL_CATEGORY' },
//                   { authorityCode: 'EDIT_PROVISIONAL_CATEGORY' },
//                   { authorityCode: 'ADD_PROVISIONAL_CATEGORY' },
//                   { authorityCode: 'PROVISIONAL_CATEGORY_SECTION' },
//                 ],
//               },
//               {
//                 menuSection: 'REFERRAL',
//                 menuAuthorityCodes: [{ authorityCode: 'REFERRAL_SECTION' }],
//               },
//               {
//                 menuSection: 'ROLES',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'ADD_NEW_ROLE' },
//                   { authorityCode: 'EDIT_ROLE_NAME' },
//                   { authorityCode: 'EDIT_ROLE_MAPPING' },
//                   { authorityCode: 'VIEW_ROLES' },
//                 ],
//               },
//               {
//                 menuSection: 'SPECIAL_PAGES',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'EDIT_SPECIAL_PAGES' },
//                   { authorityCode: 'VIEW_SPECIAL_PAGES' },
//                   { authorityCode: 'ADD_SPECIAL_PAGES' },
//                 ],
//               },
//               {
//                 menuSection: 'SYSTEM_PROPERTY',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'VIEW_SYSTEM_PROPERTY_MENU' },
//                   { authorityCode: 'EDIT_SYSTEM_PROPERTY' },
//                   { authorityCode: 'ADD_SYSTEM_PROPERTY' },
//                   { authorityCode: 'VIEW_SYSTEM_PROPERTY' },
//                 ],
//               },
//               {
//                 menuSection: 'UPLOADS',
//                 menuAuthorityCodes: [{ authorityCode: 'UPLOADS_SECTION' }],
//               },
//               {
//                 menuSection: 'USERS',
//                 menuAuthorityCodes: [
//                   { authorityCode: 'EDIT_USER_ROLES' },
//                   { authorityCode: 'VIEW_USERS' },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//       loggedIn: true,
//     },
//   });

// export const getPingCheckApi = () =>
//   request
//     .get('/crm/ping')
//     .then(resp => resp)
//     .catch(e => 'ping-pong from local');

// export const getLocationFromCDNApi = data => {
//   const { req } = request;
//   return req.get('https://geolocation-db.com/json/');
// }
